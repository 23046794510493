<template>
    <div class="content-wrapper">
        <PageHeader screenName="Editar tópico" :linkItems="linkItems"/>

        <ConfirmModal
            itemIdentifier="usuário"
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="redirecionarLista"
            @canceled="closeModalConfirm"
        />

        <SucessoModal
            :modalShow="modalSucesso.showModal"
            :typeModal="modalSucesso.typeModal"
            :caseModal="modalSucesso.caseModal"
            @confirmed="redirecionarLista"
        />

        <ErrorModal
            :modalShow="modalError.showModal"
            :typeModal="modalError.typeModal"
            :caseModal="modalError.caseModal"
            @confirmed="redirecionarLista"
        />

        <main class="card p-2">
            <section class="form">
                <validation-observer ref="ajudaRules">
                    <b-form>
                        <b-row>
                            <b-col lg="6" md="6" sm="12">
                                <b-form-group label="Título do tópico *">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Topico"
                                        rules="required">
                                        <b-form-input
                                            :state="errors.length > 0 || form.errorApi.topico? false:null"
                                            id="topico-input"
                                            v-model="form.topico"
                                            placeholder="Ex: Como fazer uma determinada ação?"
                                            :disabled="disabledAll"
                                        />
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.topico" class="text-danger">
                                            {{ form.errorApi.topico }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6" md="6" sm="12">
                                <validation-provider #default="{ errors }" name="Categorias" rules="required">
                                    <b-form-group label="Categoria *" :state="errors.length > 0 || form.errorApi.id_categoria ? false:null">
                                        <v-select
                                            id="Select-Categoria"
                                            :reduce="(option => option.id_categoria)"
                                            v-model="form.id_categoria"
                                            @input="SelecionaCategoria"
                                            variant="custom"
                                            item-text="titulo"
                                            item-value="id_categoria"
                                            label="titulo"
                                            placeholder="Selecione a categoria relacionada"
                                            :disabled="disabledAll"
                                            :options="Categorias">
                                        <span slot="no-options">Nenhuma opção selecionável.</span>
                                        </v-select>
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.id_categoria" class="text-danger">
                                            {{ form.errorApi.id_categoria }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="12" md="12" sm="12">
                                <validation-provider #default="{ errors }" name="Conteudo" rules="required">
                                    <b-form-group label="Descrição do tópico *" :state="errors.length > 0 || form.errorApi.conteudo ? false:null">
                                        <b-form-textarea
                                            id="textarea-default"
                                            placeholder="Insira o conteúdo do tópico"
                                            rows="3"
                                            v-model="form.conteudo"
                                            :disabled="disabledAll"
                                        />
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.conteudo" class="text-danger">
                                            {{ form.errorApi.conteudo }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </section>
            <section class="buttons mt-3">
                <b-button id="save-faq" class="mr-2" variant="custom-blue" @click.prevent="validationForm(true)" :disabled="saving || disabledAll">
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar alterações</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-register-faq" :disabled="saving" variant="outline-danger" @click.prevent="openModalConfirm">
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Cancelar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BButton, BFormTextarea } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import { getIdAjuda, getIdCategoria } from '@core/utils/store/getStore';
import { setIdAjuda, setIdCategoria } from "@core/utils/store/setStore";

export default {
    title: 'Editar tópico de ajuda',

    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BFormTextarea,
        BButton, vSelect, ValidationProvider, ValidationObserver,
        PageHeader, ConfirmModal, SucessoModal, ErrorModal
    },

    data() {
        return {
            linkItems: [
                {
                    name: 'Ajuda e suporte',
                    routeName: 'ajuda-list'
                },
                {
                    name: 'Editar: '+this.$route.params.topico,
                    active: true
                }
            ],
            modal: {
                showModal: false,
                typeModal: 'danger'
            },
            modalSucesso: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'edicao'
            },
            modalError: {
                showModal: false,
                typeModal: 'error',
                caseModal: 'idInexistente'
            },
            required,
            saving: false,
            disabledAll: true,
            form: {
                topico: '',
                conteudo: '',
                id_categoria: '',
                errorApi: {
                    topico: '',
                    conteudo: '',
                    id_categoria: '',
                    error: ''
                }
            },
            idAjudaEditar : null,

            CategoriaSelecionada: [],
            Categorias: []
        }
    },

    mounted(){
        if((getIdAjuda() === null || typeof getIdAjuda() == 'undefined') && typeof this.$route.params.idAjuda == "undefined") {
            return this.openModalError();
        }else if(typeof this.$route.params.idAjuda != "undefined" && this.$route.params.idAjuda !== getIdAjuda()) {
            setIdAjuda(this.$route.params.idAjuda);
        }

        if(typeof this.$route.params.categoriaSelecionada != "undefined" && this.$route.params.categoriaSelecionada !== getIdCategoria()) {
            setIdCategoria(this.$route.params.categoriaSelecionada);
        }

        this.$http.get(this.$api.ajudaIdAjuda(getIdAjuda())).then(({ data }) => {
            this.preenchimentoFormulario(data);
        });
    },

    methods: {

        SelecionaCategoria() {
            this.$emit("GetCategoria", this.CategoriaSelecionada);
        },
        buscaCategoria() {
            this.$http.get(this.$api.ajudaCategorias()).then((res) => {
                this.Categorias = res.data;
                this.disabledAll = false;

            });            
        },

        validationForm(redirectToList) {
            this.$refs.ajudaRules.validate().then(success => {
                if (success) {
                    if(redirectToList){
                        this.saving = true
                    }
                    this.disabledAll = true;
                    this.editAjuda(redirectToList);
                }
            })
        },

        editAjuda(redirectToList) {
            const parameters = {
                'topico': this.form.topico,
                'conteudo': this.form.conteudo,
                'id_categoria': this.form.id_categoria,
            }

            this.$http.put(this.$api.ajudaIdAjuda(this.idAjudaEditar), parameters).then(() => {
                if(redirectToList) {
                    this.openModalSucesso();
                }

                this.saving = false;
                this.cleanForm();

            }).catch(error => {
                this.saving = false;
                this.savingAndNew = false;
                if(typeof error.response != 'undefined'){
                    this.handleErrors(error.response)
                }else{
                    this.form.errorApi.error = 'Erro inesperado';
                }
            });
        },

        handleErrors(data) {
            this.disabledAll = false;
            if(data.data.errors) {
                let errors = data.data.errors
                if(typeof errors !== 'string') {
                    this.form.errorApi.topico = errors.topico ? errors.topico[0] : '';
                    this.form.errorApi.conteudo = errors.conteudo ? errors.conteudo[0] : '';
                    this.form.errorApi.url = errors.url ? errors.url[0] : '';
                    this.form.errorApi.id_categoria = errors.id_categoria ? errors.id_categoria[0] : false;
                    this.form.errorApi.error = '';
                    return;
                }
                this.form.errorApi.topico = '';
                this.form.errorApi.conteudo = '';
                this.form.errorApi.url = '';
                this.form.errorApi.id_categoria = false;
                this.form.errorApi.error = errors;
            }
        },

        cleanForm() {
            this.form.topico = '';
            this.form.conteudo = '';
            this.form.id_categoria = null;

            this.form.errorApi.topico = '';
            this.form.errorApi.conteudo = '';
            this.form.errorApi.id_categoria = '';
            this.form.errorApi.error = ''

            this.$refs.ajudaRules.reset();
        },

        redirecionarLista() {
            this.$router.push({ name: 'ajuda-list' });
        },

        preenchimentoFormulario(data){
            this.idAjudaEditar = getIdAjuda();
            this.form.topico = data.topico;
            this.form.conteudo = data.conteudo;
            this.form.id_categoria = getIdCategoria();
            this.linkItems[1].name = 'Editar '+data.topico;
        },

        openModalConfirm() {
            this.modal.showModal = true;
        },

        closeModalConfirm() {
            this.modal.showModal = false;
        },

        openModalSucesso() {
            this.modalSucesso.showModal = true;
        },

        openModalError() {
            this.modalError.showModal = true;
        },
    },
    created() {
        this.buscaCategoria();
    },
}
</script>

<style>
@media (max-width: 768px) {
    .buttons{
        text-align: center;
    }
}
</style>